@import url(https://fonts.googleapis.com/css?family=Rajdhani:300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@469&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.showcase_container{display:flex;min-height:100vh;justify-content:center;align-items:center}.showcase-one{color:#FFF;align-items:center;justify-content:center;padding:15px;text-align:center;font:1.33em 'Roboto Condensed', arial}.showcase-one h1{font-size:3.5em;font-size:3em;margin:.5em 0 0;padding:0;text-shadow:0 4px rgba(0,0,0,0.2)}.showcase-one h1 span{font-size:.9em}.showcase-one .live{overflow:hidden}.showcase-one .live>li{list-style:none;position:relative;padding:0 0 0 2em;margin:0 0 .5em 10px;transition:.12s}.showcase-one .live>li::before{position:absolute;content:'\2022';font-family:Arial;color:#FFF;top:0;left:0;text-align:center;font-size:1.5em;opacity:.5;transition:.5s}.showcase-one .live>li:hover{color:#FFF}.showcase-one .live>li:hover::before{transform:scale(2);opacity:1;text-shadow:0 0 4px;transition:.1s}.showcase-one .live.type2>li::before{content:'';width:10px;height:10px;background:#FFF;border-radius:3px;line-height:0;top:.27em;left:5px}.showcase-one .live.type2>li:hover::before{transform:none;border-radius:5px;width:25px;left:-10px;background:#BA5353;background:#00a3e0;background:#FFC72C}.showcase-one ol,.showcase-one ul{width:100%;display:inline-block;text-align:left;vertical-align:top;color:rgba(255,255,255,0.75);border-radius:5px;padding:1.5em;margin:2%}.dark{background-color:red;background-color:#DA291C}@font-face{font-family:'Roboto Condensed';font-style:normal;font-weight:400;src:local("Roboto Condensed Regular"),local("RobotoCondensed-Regular"),url(http://themes.googleusercontent.com/static/fonts/robotocondensed/v7/Zd2E9abXLFGSr9G3YK2MsFzqCfRpIA3W6ypxnPISCPA.woff) format("woff")}

.nav_bar_container {
  font-family: "Rajdhani", sans-serif;
  font-size: 2em;
  font-size: 1.5em;
  display: none;
  padding: 1em;
}

.nav_bar_container a {
  color: white;
}

.left_log_box,
.right_log_box {
  letter-spacing: 0.75em;
  padding-left: 0.375em;
  display: inline-block;
}

.right_log_box {
  background-color: rgba(255, 255, 255, 0.5);
}

.right_log_box_text {
  color: rgba(3, 6, 11, 0.85);
}

.nav_bar_right {
  font-size: 1em;
}

.nav_flipped a {
  color: #07101d;
}

.nav_flipped .right_log_box {
  background-color: rgba(0, 0, 0, 0.5);
}

.nav_flipped .right_log_box_text {
  color: white;
}

ul {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

li {
  padding: 0;
  margin: 0;
}

/* styling open close button*/
.button {
  display: inline;
  position: absolute;
  right: 50px;
  top: 6px;
  z-index: 999;
  font-size: 30px;
}

.button a {
  text-decoration: none;
}

.btn-open:after {
  content: "\f0c9";
  font-family: "FontAwesome";
  transition-property: all .2s linear 0s;
  -moz-transition: all .2s linear 0s;
  -webkit-transition: all .2s linear 0s;
  -o-transition: all .2s linear 0s;
}

.btn-open:hover:after {
  color: #00a3e0;
}

.btn-close:after {
  content: "\f00d";
  font-family: "FontAwesome";
  transition-property: all .2s linear 0s;
  -moz-transition: all .2s linear 0s;
  -webkit-transition: all .2s linear 0s;
  -o-transition: all .2s linear 0s;
}

.btn-close:hover:after {
  color: #00a3e0;
}

.btn-light {
  color: white;
}

.btn-dark {
  color: #07101d;
}

/*overlay*/
.nav_overlay {
  transition: 1s;
  display: none;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: #333;
  overflow: auto;
  z-index: 99;
}

.wrap {
  color: #e9e9e9;
  text-align: center;
  margin: 0 auto;
}

.wrap ul.wrap-nav {
  border-bottom: 1px solid #575757;
  text-transform: capitalize;
  padding: 150px 0px 100px;
}

.wrap ul.wrap-nav li {
  font-size: 20px;
  display: inline-block;
  vertical-align: top;
  width: 24%;
  position: relative;
}

.wrap ul.wrap-nav li a {
  color: #00a3e0;
  display: block;
  padding: 8px 0;
  text-decoration: none;
  transition-property: all .2s linear 0s;
  -moz-transition: all .2s linear 0s;
  -webkit-transition: all .2s linear 0s;
  -o-transition: all .2s linear 0s;
}

.wrap ul.wrap-nav li a:hover {
  color: #f0f0f0;
}

.wrap ul.wrap-nav ul {
  padding: 20px 0;
}

.wrap ul.wrap-nav ul li {
  display: block;
  font-size: 13px;
  width: 100%;
  color: #e9e9e9;
}

.wrap ul.wrap-nav ul li a {
  color: #f0f0f0;
}

.wrap ul.wrap-nav ul li a:hover {
  color: #00a3e0;
}

.social {
  font-size: 25px;
  padding: 20px;
}

.social p {
  margin: 0;
  padding: 20px 0 5px 0;
  line-height: 30px;
  font-size: 13px;
}

.social p a {
  color: #00a3e0;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.social-icon {
  width: 80px;
  height: 50px;
  background: #e9e9e9;
  color: #07101d;
  display: inline-block;
  margin: 0 20px;
  transition-property: all .2s linear 0s;
  -moz-transition: all .2s linear 0s;
  -webkit-transition: all .2s linear 0s;
  -o-transition: all .2s linear 0s;
}

.social-icon:hover {
  background: #00a3e0;
  color: #f0f0f0;
}

.social-icon i {
  margin-top: 12px;
}

@media screen and (max-width: 48em) {
  .wrap ul.wrap-nav > li {
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #575757;
  }
  .wrap ul.wrap-nav {
    padding: 30px 0px 0px;
  }
  nav ul {
    opacity: 0;
    visibility: hidden;
  }
  .social {
    color: #c1c1c1;
    font-size: 25px;
    padding: 15px 0;
  }
  .social-icon {
    width: 100%;
    height: 50px;
    background: #fff;
    color: #07101d;
    display: block;
    margin: 5px 0;
  }
}

.content {
  width: 100%;
  margin-top: 200px;
  font-size: 20px;
  color: #07101d;
  text-align: center;
}

footer {
  display: block;
}

footer p {
  font-family: "Josefin Sans", sans-serif;
  margin: 0px;
}

footer li {
  font-family: "Josefin Sans", sans-serif;
}

.footer_container {
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  background-color: #26272b;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #c7c7c7;
}

.footer_container p {
  font-size: 1.25em;
}

.footer_container hr {
  border-top-color: #bbb;
  opacity: 0.5;
}

.footer_container hr.small {
  margin: 20px 0;
}

.footer_container h6 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}

.footer_container a {
  color: #c7c7c7;
}

.footer_container a:hover {
  color: #3366cc;
  text-decoration: none;
}

.footer-links {
  padding-left: 0;
  list-style: none;
}

.footer-links li {
  display: block;
}

.footer-links a {
  color: #c7c7c7;
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #3366cc;
  text-decoration: none;
}

.footer-links.inline li {
  display: inline-block;
}

.footer_container .social-icons {
  text-align: right;
}

.footer_container .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #33353d;
}

.copyright-text {
  margin: 0;
}

@media (max-width: 991px) {
  .footer_container [class^=col-] {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .footer_container {
    padding-bottom: 0;
  }
  .footer_container .copyright-text,
  .footer_container .social-icons {
    text-align: center;
  }
}

.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.social-icons li {
  display: inline-block;
  margin-bottom: 4px;
}

.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px;
}

.social-icons a {
  background-color: #eceeef;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  transition: all .2s linear;
}

.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe;
}

.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}

.social-icons a.facebook:hover {
  background-color: #3b5998;
}

.social-icons a.twitter:hover {
  background-color: #00aced;
}

.social-icons a.linkedin:hover {
  background-color: #007bb6;
}

.social-icons a.dribbble:hover {
  background-color: #ea4c89;
}

@media (max-width: 767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
}

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  /* Could be more or less, depending on screen size */
}

/* Modal Body */
.modal-body {
  padding: 25px;
}

.modal-body p {
  font-size: 1.5em;
  font-family: "Rajdhani", sans-serif;
  text-align: center;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 576px) {
  .jumbotron_body h1,
  .form_header h1 {
    font-size: 4.7em !important;
    font-size: 5em !important;
  }
  .jumbotron .jumbotron_body p,
  .form_header p {
    font-size: 2em;
    font-family: 'Rajdhani', sans-serif;
  }
}

@media (min-width: 576px) {
  .jumbotron_body h1,
  .form_header h1 {
    font-size: 8em !important;
  }
  .jumbotron .jumbotron_body p,
  .form_header p {
    font-size: 2em;
    font-family: 'Rajdhani', sans-serif;
  }
}

@media (min-width: 768px) {
  .showcase-one h1 {
    font-size: 4em;
  }
  .jumbotron_body h1,
  .form_header h1 {
    font-size: 10em !important;
  }
  .jumbotron .jumbotron_body p,
  .form_header p {
    font-size: 3em;
    font-family: 'Rajdhani', sans-serif;
  }
}

@media (min-width: 992px) {
  .jumbotron_body h1,
  .form_header h1 {
    font-size: 10em !important;
  }
  .nav_bar_container {
    display: flex !important;
  }
  .hamburger_menu_container {
    display: none;
  }
}

@media (min-width: 1200px) {
  .jumbotron_body h1,
  .form_header h1 {
    font-size: 10em !important;
  }
}

@media (min-width: 1400px) {
  .jumbotron_body h1,
  .form_header h1 {
    font-size: 12em !important;
  }
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.temp_container {
  display: block !important;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5) !important;
  position: relative;
}

.jumbotron_container {
  display: flex;
  min-height: 100vh;
}

.jumbotron {
  padding: 0em;
}

.jumbotron .nav_container {
  height: 100px;
  background-color: rgba(255, 255, 255, 0.25);
}

.jumbotron .overlay {
  background-color: rgba(3, 6, 11, 0.05);
  height: 100%;
  position: fixed;
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  color: white;
}

.jumbotron .jumbotron_content {
  position: fixed;
  position: absolute;
  bottom: 0;
  background-color: rgba(3, 6, 11, 0.05);
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.jumbotron .jumboVideo {
  display: block;
  position: fixed;
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.jumbotron .jumbotron_body {
  flex-direction: column;
  height: 100%;
  height: auto;
  justify-content: center;
  padding: 2em;
  word-break: break-word;
}

.jumbotron .jumbotron_body h1 {
  font-size: 5em;
  font-family: 'Josefin Sans', sans-serif;
}

.jumbotron .jumbotron_body p {
  font-family: "Oswald";
  text-transform: uppercase;
  margin: 1em 0em;
}

.jumbotron .jumbotron_body .ghost_button {
  color: #fff !important;
}

.body_container {
  display: block;
  background-color: white;
  height: 100vh;
}

.feature_box {
  display: block;
  height: 300px;
  background-color: red;
}

.clear {
  clear: both;
}

.form_header {
  word-break: break-word;
}

.form_header h1 {
  font-family: "Josefin Sans", sans-serif;
  text-transform: uppercase;
}

.form_header h3 {
  font-family: "Rajdhani", sans-serif;
  font-family: "Oswald";
}

.form_header p {
  font-family: "Oswald";
  text-transform: uppercase;
  text-align: right;
  margin: 3em 0em;
}

.brown {
  background-color: #ded1c1;
  color: #2d2b29;
}

.other {
  background-color: #BBDEE9;
  color: #2d2b29;
}

.nav_overlay {
  padding: 1em;
  padding: 2em;
}

.nav_overlay p {
  font-family: "Oswald";
  text-transform: uppercase;
  text-align: center;
  font-size: 2em;
  margin: 0em;
}

.nav_overlay h3 {
  font-family: "Rajdhani", sans-serif;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bolder;
}

.form_body {
  background-color: rgba(0, 0, 0, 0.05);
}

button {
  min-width: 170px;
  background: none;
  border: 0;
  box-sizing: border-box;
  margin: 1em 0em;
  padding: 1em 2em;
  font-size: inherit;
  font-weight: 700;
  position: relative;
  vertical-align: middle;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.75);
  color: rgba(255, 255, 255, 0.75);
  background-color: rgba(0, 0, 0, 0.005);
}

button::before, button::after {
  box-sizing: inherit;
  content: '';
  position: absolute;
}

.draw {
  transition: color .25s;
}

.draw::before, .draw::after {
  border: 2px solid transparent;
  width: 0;
  height: 0;
}

.draw::before {
  top: 0;
  left: 0;
}

.draw::after {
  bottom: 0;
  right: 0;
}

.draw:hover {
  color: white;
}

.draw:hover::before, .draw:hover::after {
  width: 100%;
  height: 100%;
}

.draw:hover::before {
  border-top-color: white;
  border-right-color: white;
  transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
}

.draw:hover::after {
  border-bottom-color: white;
  border-left-color: white;
  transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s, height 0.25s ease-out 0.75s;
}

.center:hover {
  color: #6477b9;
}

.center::before, .center::after {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform-origin: center;
}

.center::before {
  border-top: 2px solid #6477b9;
  border-bottom: 2px solid #6477b9;
  transform: scale3d(0, 1, 1);
}

.center::after {
  border-left: 2px solid #6477b9;
  border-right: 2px solid #6477b9;
  transform: scale3d(1, 0, 1);
}

.center:hover::before, .center:hover::after {
  transform: scale3d(1, 1, 1);
  transition: transform 0.5s;
}

.hamburger_menu_container {
  padding: 1em;
  padding: 2em;
}

.form_container{display:flex;justify-content:center;align-items:center;min-height:100vh;background-color:rgba(255,255,255,0.95);position:relative}.form_container h2{text-align:center}.form_container h3{font-size:35px;font-family:"Rajdhani",sans-serif;text-align:center}.form_container .three_d_container{min-height:100vh;height:100%;width:100%;position:absolute;top:0;left:0;z-index:-99}.form_container .ghost_button_container{display:flex;align-items:center;justify-content:center;margin-top:1em;margin-bottom:1em}.form_container .ghost_button{border:0.1px solid black;color:black;float:right}.form_container h2{font-family:"Josefin Sans",sans-serif;font-size:4em;text-align:center;margin-top:1em;margin-bottom:1em}.form_container input[type="text"],.form_container input[type="date"],.form_container input[type="datetime"],.form_container input[type="email"],.form_container input[type="number"],.form_container input[type="search"],.form_container input[type="time"],.form_container input[type="tel"],.form_container input[type="url"],.form_container input[type="password"],.form_container textarea,.form_container select{box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;outline:none;display:block;width:100%;padding:7px;border:none;border-bottom:1px solid #ddd;border-bottom:1px solid #2c2d32;background:transparent;margin-bottom:10px;height:45px;color:#fff;color:#2c2d32;font-size:20px}.form_container input:invalid{border-bottom:2px solid red}.form_container textarea{resize:none;overflow:hidden}.form_container input[type="text"]::-webkit-input-placeholder,.form_container input[type="text"]::-moz-placeholder,.form_container input[type="text"]::-moz-placeholder,.form_container input[type="text"]::-ms-input-placeholder{font-family:'Roboto', sans-serif}

